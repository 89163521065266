<template>
	<div class="look_people">
		<div class="home_center" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.8)">

			<div class="">
				<div class="center_left">
					<div class="left_title">
						<!-- 委托人 -->{{$t('job_detail.WTR')}}
					</div>
					<el-checkbox-group v-model="listData.entrust_method">
						<!-- <el-radio label="">{{$t('activity.QB')}}</el-radio> -->
						<el-checkbox :label="item.data_code" v-for="item in entrust" :key="item.data_code">{{item.data_value}}</el-checkbox>
					</el-checkbox-group>
					<!-- <el-checkbox-group v-model="listData.entrust_method">
						<el-checkbox :label="item.data_code" v-for="item in entrust" :key="item.data_code">{{item.data_value}}</el-checkbox>
						
					</el-checkbox-group> -->
					
					<div class="left_title">
						<!-- 体裁 -->{{$t('job_detail.TC')}}
					</div>
					<!-- <el-checkbox-group v-model="listData.skill">
						<template v-if="showallskill" >
							<el-checkbox :label="sitem.label" v-for="sitem in allskill" :key="sitem.value" style="display: block;"></el-checkbox>
						</template>
						<template v-else >
							<el-checkbox :label="sitem.label" v-for="sitem in tenskill" :key="sitem.value" style="display: block;"></el-checkbox>
						</template>
						<div @click="showallskill=!showallskill" style="font-size: 12px;color: #fff;cursor: pointer;margin-top: 10px;">{{$t('CKGD')}}<i class="el-icon-arrow-up" v-if="showallskill"></i><i class="el-icon-arrow-down" v-else></i></div>
					</el-checkbox-group> -->
					<el-checkbox-group v-model="listData.skill">
						
						<template v-for="item in skillList">
							<div class="typeTitle" :class="{open:showids.indexOf(item.value)>-1}" @click="toggleSkill(item.value)">{{item.label}}</div>
							<div v-if="showids.indexOf(item.value)>-1"><el-checkbox :label="sitem.label" v-for="sitem in item.children" :key="sitem.value" style="margin: 10px 0 0;" ></el-checkbox></div>
						</template>
						
						
						
					</el-checkbox-group>
					
					<div class="left_title">
						<!-- 其他筛选 -->{{$t('job_detail.QTSX')}}
					</div>
					<div style="margin-bottom: 30px;">
					    <el-checkbox :true-label="1" :false-label="0" v-model="listData.job_method"><!-- 可远程 -->{{$t('job_detail.KYC')}}</el-checkbox>
					
					    <el-checkbox :true-label="1" :false-label="0" v-model="listData.fast_method"><!-- 急聘 -->{{$t('job_detail.JP')}}</el-checkbox>
					
					    <el-checkbox :true-label="$t('job.FY')" false-label="" v-model="listData.job_nature"><!-- 可兼职 -->{{$t('job_detail.KJZ')}}</el-checkbox>
					</div>
					<!-- <el-checkbox-group v-model="checkList">
						<el-checkbox label="可远程"></el-checkbox>
						<el-checkbox label="急聘"></el-checkbox>
						<el-checkbox label="复数募集"></el-checkbox>
						<el-checkbox label="可兼职"></el-checkbox>
					</el-checkbox-group> -->
					<div class="left_title">
						<!-- 报酬 -->{{$t('job_detail.BC')}}
					</div>
					<div class="left_input_box">
						<el-input v-model="listData.salary_low" placeholder="" class="input"></el-input>
						<div class="input_box">
							<!-- 以上 -->{{$t('MIN')}}〜
						</div>
					</div>
					<!-- <div class="left_input_box">
						<el-input v-model="listData.salary_high" placeholder="" class="input"></el-input>
						<div class="input_box">
							{{$t('MAX')}}〜
						</div>
					</div> -->
					
				</div>

			</div>


			<div class="center_people">
				<div class="people_search">
					<div class="search_box">
						<img src="../../assets/img/search.png" class="search_img" alt="">						
						<el-input :placeholder="$t('USEKEYWORD')" class="search_input"  v-model="listData.keyword" clearable ></el-input>
					</div>
					<div class="search_menu">
						<div class="menu" :class="{active:sortIndex==0}" @click="sort('hot_score',0)">
							<!-- 热门排序 -->{{$t('RMPX')}}
							<i class="el-icon-arrow-down" v-if="listData.sortRules.hot_score=='DESC'"></i>
							<i class="el-icon-arrow-up" v-if="listData.sortRules.hot_score=='ASC'"></i>
						</div>
						<div class="menu" :class="{active:sortIndex==1}" @click="sort('job_id',1)">
							<!-- 最新发布 -->{{$t('ZXFB')}}
							<i class="el-icon-arrow-down" v-if="listData.sortRules.job_id=='DESC'"></i>
							<i class="el-icon-arrow-up" v-if="listData.sortRules.job_id=='ASC'"></i>
						</div>
						<div class="menu" :class="{active:sortIndex==2}" @click="sort('salary_low',2)">
							<!-- 佣金高低 -->{{$t('other.YJGD')}}
							<i class="el-icon-arrow-down" v-if="listData.sortRules.salary_low=='DESC'"></i>
							<i class="el-icon-arrow-up" v-if="listData.sortRules.salary_low=='ASC'"></i>
						</div>
						<!-- <div class="menu" :class="{active:sortIndex==3}" @click="sort('create_time',3)">
							{{$t('other.JBMSJ')}}
							<i class="el-icon-arrow-down" v-if="listData.sortRules.create_time=='DESC'"></i>
							<i class="el-icon-arrow-up" v-if="listData.sortRules.create_time=='ASC'"></i>
						</div> -->
						<div class="menu" :class="{active:sortIndex==4}" @click="sort('job_end_time',4)">
							<!-- 截止报名时间 -->{{$t('JZBMSJ')}}
							<i class="el-icon-arrow-down" v-if="listData.sortRules.job_end_time=='DESC'"></i>
							<i class="el-icon-arrow-up" v-if="listData.sortRules.job_end_time=='ASC'"></i>
						</div>
					</div>
				</div>
				<div class="people_list" >
					<div class="activity_box" style="cursor: pointer;" v-for="(item,index) in list" :key="item.job_id" @click="job_page(item.job_id)">
						<img :src="item.avatar_url" class="box_img" alt="">
						<div class="box_msg">
							<div class="msg_name">
								{{item.job_name}}
							</div>
							<!-- <div class="msg_msg">
								东京三菱UFJ，IT
							</div> -->
							<div class="msg_msg">
								{{item.job_desc}}
							</div>
							<div class="msg_text">
								<!-- 必要技能 -->{{$t('BYJN')}}：{{item.skills.join(' , ')}}
							</div>
						</div>
						<div class="box_center">
							<div class="center_text">
								<div class="text_text">
									<!-- 时间 -->{{$t('TIME')}}
								</div>
								<div class="text_box">
									
									{{item.oper_time_val}}{{$util.getUnitVal(operTimeMethod,item.oper_time_method)}}
								</div>
								<!-- <div class="text_box" v-if="item.oper_time_method==0">
									{{item.oper_time_val}}
								</div>
								<div class="text_box" v-if="item.oper_time_method==1">
									{{item.oper_time_val}}
								</div>
								<div class="text_box" v-if="item.oper_time_method==3">
									{{$util.getUnitVal(operTimeMethod,item.oper_time_method)}}
								</div> -->
							</div>
							<div class="center_text">
								<div class="text_text">
									<!-- 委托方式 -->{{$t('job_detail.WTFS')}}
								</div>
								<div class="text_box">
									{{$util.getUnitVal(jobBelong,item.job_belong_method)}}
								</div>
							</div>
							<div class="center_text">
								<div class="text_text">
									<!-- 佣金 -->{{$t('YJ')}}
								</div>
								<div class="text_box">
									{{item.salary_low}} - {{item.salary_high}} {{item.salary_unit}}
								</div>
							</div>
							<div class="center_text">
								<div class="text_text">
									<!-- 需要人数 -->{{$t('other.XYRS')}}
								</div>
								<div class="text_box">
									{{item.job_num_people}}<!-- 人 -->{{$t('REN')}}
								</div>
							</div>
						</div>
						<div class="box_btn">
							<div class="btn">
								<img src="../../assets/img/fengx.png" class="fenx" alt="">
								<!-- 分享 -->{{$t('task.FX')}}
								<div class="sharemenu">
									<div @click.stop="share(item.job_id,'facebook',item.job_name)">Facebook</div>
									<div @click.stop="share(item.job_id,'Linkedin',item.job_name)">LinkedIn</div>
									<div @click.stop="share(item.job_id,'twitter',item.job_name)">Twitter</div>
								</div>
							</div>
							<div class="btn" @click.stop="fav(item.job_id,index)">								
								<img src="../../assets/img/biaoq2.png" class="fenx" alt="" v-if="item.is_collect==1">
								<img src="../../assets/img/biaoq.png" class="fenx" alt="" v-else>
								<!-- 保存 -->{{$t('FAV')}}
							</div>
							<div class="btn" @click.stop="job_page(item.job_id)">
								<img src="../../assets/img/search.png" class="fenx" alt="">
								
								<!-- 查看详情 -->{{$t('other.CKXQ')}}
							</div>
						</div>
					
					</div>
					
					
					
					
					
					
				</div>


			</div>
			
			<!-- 消息列表 -->
			<Homeright></Homeright>
		</div>
		<!-- 回复弹窗 -->
		<!-- <Reply></Reply> -->
		<!-- 全部评论 -->
		<!-- <Pinlun></Pinlun> -->
	</div>
</template>

<script>
	import Homeright from '../../components/home/home_right.vue';
	import scroll from '@/utils/scroll.js';
	import {debounce} from '@/utils/debounce.js';
	export default {
		components: {
			Homeright,
		},
		data() {
			return {
				
				loading:false,
				listData:{
					pageSize: 15,
					keyword: '',
					skill: [],					
					salary_low: '',
					salary_high: '',
					entrust_method:[],
					job_method:'',
					fast_method:'',
					job_nature: '',
					sortRules: {
						hot_score: 'DESC',
						job_id: '',
						salary_low: '',
						create_time: '',
						job_end_time: ''
					}
				},
				currentPage: 1,
				
				isLast:0,
				list:[],
				show: false,
				
				
				skillList:[],
				entrust:[],
				jobBelong:[],
				operTimeMethod:[],
				sortIndex:0,
				
				showallskill:false,
				tenskill:[],
				allskill:[],
				showids:[]
			};
		},
		created() {
			//console.log(this.$route.params);
			for(let i in this.$route.params){
				//console.log(i);
				this.listData[i]=this.$route.params[i];
			}
			this.getList();
			scroll.scrollToBottom(this.getList);
			this.getUnit();
			this.getSkill();
		},
		computed:{
		    watchlistData(){
		        return JSON.parse(JSON.stringify(this.listData));
		    }
		},
		watch:{
			watchlistData:{
				handler:function(nval,oval){
					for(let i in nval){
						if(nval[i]!=oval[i]){
							//console.log(i);
							if(i=='keyword'||i=='salary_low'||i=='salary_high'){
								this.search();
								return;
							}
						}
					}
					this.getList('reload');
				},
				deep:true			
			},
			
		},
		methods:{
			toggleSkill(id){
				if(this.showids.indexOf(id)==-1){
					this.showids=[...this.showids,...[id]];
				}else{
					this.showids.splice(this.showids.indexOf(id),1);
				}
			},
			share:function(id,type,title){
				var href=this.$config.website+'job_detail?job_id='+id;				
				this.$util.share(href,type,title);
			},
			async job_page(id){
				this.$router.push('/job_detail?job_id='+id);
			},
			//排序
			sort(key,index){
				for(let i in this.listData.sortRules){
					if(i==key){
						this.listData.sortRules[key]=this.listData.sortRules[key]=='DESC'?'ASC':'DESC';
					}else{
						this.listData.sortRules[i]='';
					}
				}
				
				this.sortIndex=index;
			},
			//收藏
			async fav(id,index){
				let res=await this.$request.get('/api/custJobManage/collectJob',{job_id:id});				
				this.list[index].is_collect=this.list[index].is_collect==1?0:1;				
			},
			//获取业务技能数据
			async getSkill(){
				let res=await this.$request.get('/api/setjobs/getAllSkillList');
				this.skillList=res.data.records;
				let list=[],list2=[];
				for (var i = 0; i < this.skillList.length; i++) {
					if(list2.length<10){
						list.push({value:this.skillList[i].value,label:this.skillList[i].label,children:[]});
					}
					for (var a = 0; a < this.skillList[i].children.length; a++) {
						list2.push(this.skillList[i].children[a]);
						if(list2.length<=10){
							list[i].children.push(this.skillList[i].children[a]);
						}
						
					}
				}
				this.tenskill=list;
				this.allskill=list2;
				// console.log('技能',list,list2);
			},
			//获取字典数据
			async getUnit(){
				let res=await this.$request.get('/api/sysdict/getDictList');
				this.jobNature=res.data.job_nature.data;
				this.entrust=res.data.entrust_method.data;
				this.jobBelong=res.data.job_belong_method.data;
				this.operTimeMethod=res.data.oper_time_method.data;
			},
			search:debounce(function(){				
				this.getList('reload');
			}),
			//获取工作
			  async getList(t){
				if(t=='reload'){
					this.currentPage=1;
					this.isLast=0;			  	
				}
				if(this.isLast==1||this.loading){
					return;
				}
				console.log('加载数据');
				
				let data=JSON.parse(JSON.stringify(this.listData));
				data.currentPage=this.currentPage;
				this.loading=true;
				let res=await this.$request.post('/api/job/findPage',data);
				this.loading=false;
				if(t=='reload'){
					this.list=res.data.records;
				}else{
					if(res.data.records.length>0){
						this.list=[...this.list,...res.data.records];
					}
				}
				
				if(this.currentPage>=res.data.totalPage){
					this.isLast=1;
				}else{
					this.currentPage+=1;
				}
				
			  }
			
		}
	};
</script>

<style lang="less" >
	.typeTitle{cursor: pointer; font-size: 14px;margin: 20px 0 5px;color: rgba(#fff,.75);font-weight: bold;
		&:before{content:"";border-left: 6px solid rgba(#fff,.75);border-top: 6px solid transparent;border-bottom: 6px solid transparent;line-height: 1.2;font-size: 0;vertical-align: middle;margin-right: 5px;display: inline-block;}		
		&.open:before{transform: rotateZ(90deg);}
	}
	.look_people {
		min-height: 100%;
		background-color: #212121;

		.center_left {
			width: 286px;
			min-height: 733px;
			background: #567BB6;
			border-radius: 3px;
			padding: 30px;
			box-sizing: border-box;

			.left_title {
				height: 30px;
				font-size: 15px;
				border-bottom: 1px solid #fff;
				color: #fff;
			}
			.left_input_box{
				display: flex;
				align-items: center;
				color: #fff;
				margin-top: 15px;
				font-size: 15px;
				.input{
					width: 150px;
					height: 34px;
					background: #FFFFFF;
					border-radius: 7px;
					border: 1px solid #567BB6;
					margin-right: 8px;
				}
			}
			.el-checkbox-group {
				margin-bottom: 30px;
			}
			
			.el-radio-group{display: block;margin-bottom: 30px;}
			.el-radio{display: block; margin: 15px 0; color: #fff;}
			.el-checkbox {
				width: 100%;
				margin: 10px 0;
				color: #fff;
			}
		}

		.home_center {
			display: flex;
			justify-content: flex-end;
		}

		.center_people {
			padding: 10px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 0;
			
			.people_search {
				padding: 20px;
				box-sizing: border-box;
				width: 878px;
				min-height: 101px;
				background: #000000;
				border-radius: 7px;
				margin: 0 15px;
				margin-bottom: 30px;
				margin-top: 0;
				.search_box {
					width: 837px;
					height: 34px;
					border-radius: 7px;
					border: 1px solid #567BB6;
					display: flex;
					align-items: center;
					padding:0 15px;
					box-sizing: border-box;
					.el-input__inner{background: none;height:100%;padding: 0 30px 0 0;border:none;color:#fff;}
					.el-input__suffix{top:-12px;right: 0;}
					.search_img {
						width: 20px;
						height: 20px;
					}

					.search_input {
						background: #000000;
						color: #fff;
						border: none;
						font-size: 14px;
						margin-left: 10px;
						flex:1;
					}
				}

				.search_menu {
					display: flex;
					align-items: center;
					color: #fff;
					font-size: 14px;
					padding-top: 20px;

					.menu {
						cursor: pointer;
						margin-right: 30px;
					}

					.active {
						margin-right: 30px;
						font-weight: bold;
						color: #567BB6;
					}
				}
			}

			.people_list {
				width: 878px;

				.activity_box{
					width: 100%;
					padding: 20px;
					background-color: #000000;
					border-radius: 5px;
					margin: 10px 0;
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 20px;
					box-sizing: border-box;
					.box_img{
						width: 116px;
						height: 116px;
						border-radius: 3px;
						object-fit: cover;
					}
					.box_msg{
						//width: 370px;
						max-width: 375px;
						flex:1;margin:0 15px;
						font-size: 15px;
						color: #fff;
						.msg_name{
							text-overflow: -o-ellipsis-lastline;
							  overflow: hidden;
							  text-overflow: ellipsis;
							  display: -webkit-box;
							  -webkit-line-clamp: 2;
							  line-clamp: 2;
							  -webkit-box-orient: vertical;
							  line-height: 1.3;
							  margin-bottom: 10px;
						}
						.msg_msg{
							//width: 170px;
							font-size: 12px;
							text-overflow: -o-ellipsis-lastline;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							line-clamp: 2;
							-webkit-box-orient: vertical;
							line-height: 1.3;
							margin-bottom: 10px;
						}
						.msg_text{
							font-size: 12px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}
					.box_center{
						width: 240px;
						border-left: 1px solid #606060;
						border-right: 1px solid #606060;
						padding: 0 10px;
						color: #fff;
						font-size: 10px;
						.center_text{
							display: flex;
							align-items: center;
							justify-content: space-between;
							margin-bottom: 15px;
							.text_text{
								width: 50px;
								flex-shrink: 0;
							}
							.text_box{
								border-radius: 3px;
								width: 170px;
								line-height: 26px;
								border: 1px solid #344A6E;
								padding-left: 5px;
								
							}
						}
					}
					.box_btn{
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						//width: 40px;
						color: #fff;
						font-size: 10px;
						margin-left: 15px;
						.btn{cursor: pointer;
							display: flex;
							flex-direction: column;
							align-items: center;
							margin: 10px 0;
							.fenx{
								width: 20px;
								height: 20px;
								margin-bottom: 10px;
							}
							position: relative;
							&:hover .sharemenu{display: block;}
						}
					}
				}
			}

		}
	}
</style>
